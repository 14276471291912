import { Component, OnInit, ViewContainerRef, ViewChild, Input } from '@angular/core';
import { ScoreModel } from 'src/app/model/scoremodel';
import { VideosModel } from 'src/app/model/videosModel';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'spieslikeyou',
  templateUrl: './spieslikeyou.component.html',
  styleUrls: ['./spieslikeyou.component.scss']
})
export class SpieslikeyouComponent implements OnInit {

  @ViewChild("videoFrame", { read: ViewContainerRef }) videoFrame;
  @ViewChild("videoContainer", { read: ViewContainerRef }) videoContainer;
  
  private _context:ScoreModel[];
  channelUrl:SafeResourceUrl;
  channelText:string;
  videoUrl:SafeResourceUrl;

  constructor(private videosModel:VideosModel, private sanitizer:DomSanitizer) { 
    this.channelText = videosModel.linkText;
    //this.channelUrl = videosModel.linkUrl;
  }

  ngOnInit() {
    window.addEventListener("resize", this.updateFrameSize);
    this.updateFrameSize();
  }

  updateFrameSize = () => {
     var height = this.videoContainer.element.nativeElement.offsetWidth * .5625;
     this.videoFrame.element.nativeElement.height =height;
  }

    @Input() set context(scores :ScoreModel[]){
      this._context = scores;

      if(scores.length == 0){
        return;
      }

      scores.sort(function(a,b) {return b.percentile - a.percentile});
      var topTwo:ScoreModel[] = scores.slice(0,Math.min(scores.length,2));

      var videos:string[] = this.videosModel.videoLookup[topTwo[0].id];
      var randomVideo = videos[Math.floor(Math.random() * videos.length)]+"?modestbranding=1";
      this.videoUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(randomVideo);
      this.channelUrl = this.sanitizer.bypassSecurityTrustUrl(this. videosModel.linkUrl);
  }

  get():ScoreModel[]{
    return this._context;
  }

}
