import { Component, OnInit, Input } from '@angular/core';
import { MediaItem } from 'src/app/model/mediaitem';

@Component({
  selector: 'missiongallery',
  templateUrl: './missiongallery.component.html',
  styleUrls: ['./missiongallery.component.scss']
})
export class MissiongalleryComponent implements OnInit {

  @Input() public context:MediaItem[];
  
  constructor() { }

  ngOnInit() {
   
  }
}
