import { Component, OnInit, Input } from '@angular/core';
import { SkillScoreModel } from 'src/app/model/skillscoremodel';

@Component({
  selector: 'skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

  constructor() { }

  _context :SkillScoreModel;
  @Input() set context(value :SkillScoreModel){
    this._context = value;
  }

  get context():SkillScoreModel{
    return this._context;
  }

  ngOnInit() {
  }

}
