import { Component, OnInit, Input } from '@angular/core';
import { Profile } from 'src/app/model/profile';

@Component({
  selector: 'missiondetails',
  templateUrl: './missiondetails.component.html',
  styleUrls: ['./missiondetails.component.scss']
})
export class MissiondetailsComponent implements OnInit {

  @Input() public context:Profile;

  constructor() { }

  ngOnInit() {
  }

}
