import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ActivityStatModel } from '../model/activitystatmodel';
import { ActivityModel } from '../model/activitymodel';

@Component({
  selector: 'activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent  {

  private _context:ActivityModel;
  stats:ActivityStatModel[];

  constructor(private elementRef:ElementRef){

  }

  @Input() public set context(model:ActivityModel){

      this._context = model;

      var list = [];
      for(let k of this.context.values){
         var stat = new ActivityStatModel();
         stat.label = k.label;
         stat.value = this.convertValue(k.value);
         list.push(stat);
      }

      var  cols = 3 * list.length;
      this.elementRef.nativeElement.classList.add("col-md-"+(cols));
      this.elementRef.nativeElement.classList.add("col-12");
      this.elementRef.nativeElement.classList.add("col-sm-"+ (cols * 2));

      this.stats = list;
  }

  public get context():ActivityModel{
    return this._context;
}

  private convertValue(value:string):string{
     if(value.toLowerCase() == "false") return "NO";
     if(value.toLowerCase() == "true") return "YES";
     return value;
  }

  /*protected getValue(key:string){
    for(let k of this.context.values){
        if(k.key == key){
            return k.value;
        }
    }

    return "";
    }*/

}
