import { Component, OnInit, Input } from '@angular/core';
import { ActivityStatModel } from 'src/app/model/activitystatmodel';


@Component({
  selector: 'activitystat',
  templateUrl: './activitystat.component.html',
  styleUrls: ['./activitystat.component.scss']
})
export class ActivitystatComponent implements OnInit {

  @Input() context:ActivityStatModel;

  constructor() { }

  ngOnInit() {
  }

}
