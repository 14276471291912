import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { DebriefModel } from '../model/debriefmodel';

@Component({
  selector: 'debrief',
  templateUrl: './debrief.component.html',
  styleUrls: ['./debrief.component.scss']
})
export class DebriefComponent implements OnInit {

  constructor(private element:ElementRef, protected resolver: ComponentFactoryResolver) { }

  public percentile:number;
  public percentileSide:string;

 // @ViewChild("container", { read: ViewContainerRef }) container;
  @Input() color:string;
  protected _context:DebriefModel;
  @Input() set context(model:DebriefModel){

    this._context = model;
    var percent = this.roundPercentile(model.score.percentile);
    var side = "TOP";
    if(percent == -1){
      side= "BOTTOM";
      percent = 50;
    }

    console.log(this._context.activities);

    this.percentile = percent;
    this.percentileSide = side;

    console.log(this.getFillColor());

    /*for (let activity of model.activities) {
        this.loadComponent(activity);
    }*/

  }

  getFillColor(): any {
    var parent = this.element.nativeElement.parentElement;
    var index = Array.from(parent.parentNode.children).indexOf(parent);
    return index % 2 == 0 ? "#01418b" : "#c6101c";
  }

  roundPercentile(value:number):number{

    if(value >= .90){
      return 10;
    }else if(value >= .75){
      return 25;
    }else if(value >=.65){
      return 35;
    }else if(value >= .50){
      return 50;
    }

    return -1;
}

 /* loadComponent(activity:ActivityModel){
    var type = ComponentLookup[activity.id];
    if(!type)return;

    var factory = this.resolver.resolveComponentFactory(type);
    let componentRef = this.container.createComponent(factory);
    componentRef.instance.context = activity;
    componentRef.context = activity;
  }*/

  get context(): DebriefModel {
    return this._context;
  }

  ngOnInit() {
   
  }

}
