import { Component, OnInit, Input } from '@angular/core';
import { SkillLookup } from '../model/skillLookup';
import { ScoreModel } from '../model/scoremodel';
import { SkillScoreModel } from '../model/skillscoremodel';

@Component({
  selector: 'otherskills',
  templateUrl: './otherskills.component.html',
  styleUrls: ['./otherskills.component.scss']
})
export class OtherSkillsComponent implements OnInit {

  private _context:ScoreModel[];
  public skills:SkillScoreModel[];

  constructor(private skillLookup:SkillLookup)  {  }

  @Input() set context(scores :ScoreModel[]){
      this._context = scores;

      if(scores.length == 0){
        this.skills = [];
        return;
      }

      scores.sort(function(a,b) {return b.percentile- a.percentile});
      var topTwo:ScoreModel[] = scores.slice(2);

      var viewModels:SkillScoreModel[] = [];
      for(let score of topTwo){
          var topscore = new SkillScoreModel();

          if(score.activities.length == 0) {
            continue;
          }
          topscore.description = score.description;
          topscore.skillname = score.medalName;
          topscore.friendlyName = score.friendlyName;
          topscore.skillID = score.id;
          topscore.activities = score.activities;
          topscore.iconUrl = this.skillLookup.icons[score.id];

          viewModels.push(topscore);
      }
      this.skills = viewModels;
  }

  roundPercentile(value:number):number{

      if(value >= .90){
        return 10;
      }else if(value >= .75){
        return 25;
      }else if(value >=.65){
        return 35;
      }else if(value >= .50){
        return 50;
      }

      return -1;
  }

  get():ScoreModel[]{
    return this._context;
  }

  ngOnInit() {
  }

}
