import { Component, OnInit, Input } from '@angular/core';
import { SkillLookup } from '../model/skillLookup';
import { ScoreModel } from '../model/scoremodel';
import { TopScoreModel } from '../model/topscoremodel';

@Component({
  selector: 'topskills',
  templateUrl: './topskills.component.html',
  styleUrls: ['./topskills.component.scss']
})
export class TopSkillsComponent implements OnInit {

  private _context:ScoreModel[];
  public topScores:TopScoreModel[];

  constructor(private skillLookup:SkillLookup)  {  }

  @Input() set context(scores :ScoreModel[]){
      this._context = scores;

      if(scores.length == 0){
        this.topScores = [];
        return;
      }

      scores.sort(function(a,b) {return b.percentile- a.percentile});
      var topTwo:ScoreModel[] = scores.slice(0,Math.min(scores.length,2));

      var viewModels:TopScoreModel[] = [];
      for(let score of topTwo){
          var topscore = new TopScoreModel();
          topscore.description = score.description;
          topscore.medallionUrl = this.skillLookup.medallions[score.id];
          topscore.percentile = this.roundPercentile(score.percentile);
          topscore.text = "Your performance puts you among the ";
          topscore.skillname = score.medalName;
          topscore.skillID = score.id;
          topscore.activities = score.activities;
          topscore.summary = score.summary;

          if( topscore.percentile == -1){
            topscore.percentile = 0;
            topscore.text += "bottom";
          }else{
            topscore.text += "top";
          }
          viewModels.push(topscore);
      }
      this.topScores = viewModels;
  }

  roundPercentile(value:number):number{

      if(value >= .90){
        return 10;
      }else if(value >= .75){
        return 25;
      }else if(value >=.65){
        return 35;
      }else if(value >= .50){
        return 50;
      }

      return -1;
  }

  get():ScoreModel[]{
    return this._context;
  }

  ngOnInit() {
  }

}
