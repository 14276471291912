
import { MediaItem } from './mediaitem';
import { CareerLocation } from './careerlocation';
import { ScoreModel } from './scoremodel';
import { DebriefModel } from './debriefmodel';
import { Profile } from './profile';

export class DataModel{
    public profile:Profile = new Profile();
    public media:MediaItem[] = [];
    public spycareer:CareerLocation[];
    public skills:ScoreModel[] = [];
    public cognitive:DebriefModel;
    public physical:DebriefModel;
}









