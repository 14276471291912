import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { TakeawayimageComponent } from './welcome/takeawayimage/takeawayimage.component';
import { MissiongalleryComponent } from './welcome/missiongallery/missiongallery.component';
import { MissiondetailsComponent } from './welcome/missiondetails/missiondetails.component';
import { SocialbuttonsComponent } from './socialbuttons/socialbuttons.component';
import { SpycareerComponent } from './welcome/spycareer/spycareer.component';
import { CareerlocationComponent } from './welcome/careerlocation/careerlocation.component';
import { DebriefComponent } from './debrief/debrief.component';
import { SkillNamePipe } from './pipes/skillpipe';
import { PortalComponent } from './portal/portal.component';
import { TopSkillsComponent } from './skills/topskills.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ActivityComponent } from './activity/activity.component';
import { ActivitystatComponent } from './activity/activitystat/activitystat.component';
import { SkillArrayPipe } from './pipes/skillarraypipe';
import { ActivityTitleComponent } from './activity/activitytitle/activitytitle.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { UpgradeBrowserComponent } from './upgrade-browser/upgrade-browser.component';
import { FacebookModule } from 'ngx-facebook';
import { TopskillComponent } from './skills/topskill/topskill.component';
import { SpieslikeyouComponent } from './spieslikeyou/spieslikeyou.component';
import { OtherSkillsComponent } from './skills/otherskills.component';
import { SkillComponent } from './skills/skill/skill.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    TakeawayimageComponent,
    MissiongalleryComponent,
    MissiondetailsComponent,
    SocialbuttonsComponent,
    SpycareerComponent,
    CareerlocationComponent,
    TopskillComponent,
    TopSkillsComponent,
    SpieslikeyouComponent,
    DebriefComponent,
    SkillNamePipe,
    SkillArrayPipe,
    PortalComponent,
    PagenotfoundComponent,
    ActivityComponent,
    ActivitystatComponent,
    ActivityTitleComponent,
    UpgradeBrowserComponent,
    OtherSkillsComponent,
    SkillComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    FacebookModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
