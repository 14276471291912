import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class VideosModel {
  public linkText:string;
  public linkUrl:string;
  public videoLookup = [];
}