import { Pipe, PipeTransform } from '@angular/core';
import { SkillLookup } from '../model/skillLookup';
import { ValueTransformer } from '@angular/compiler/src/util';
import { ALLOW_MULTIPLE_PLATFORMS } from '@angular/core/src/application_ref';

@Pipe({name: 'skillArray'})
export class SkillArrayPipe implements PipeTransform {

  constructor(public skillLookup:SkillLookup){
  }

  transform(value: string[]): string {
    var newValue = "";
    var count = 0;
    for(var i = 0; i < value.length; i++){
      var id = value[i];
      var name = this.skillLookup.skills[id];
      if(!name) continue;

      var seperator = "";
      if(i > 0){
        seperator = (i == value.length - 1) ? " & " : " ";
       }
      count++;
      newValue += seperator + name;
    }
    var prefix = "";
    if(count == 1){
      prefix = "Spy Skill: ";
    } else if (count > 1){
      prefix = "Spy Skills: "
    } 
    return prefix + newValue;
  }
}