import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'socialbuttons',
  templateUrl: './socialbuttons.component.html',
  styleUrls: ['./socialbuttons.component.scss']
})
export class SocialbuttonsComponent implements OnInit {

  _linkURL:string;
  @Input() set linkURL(value:string){
    this._linkURL = value;
    this.facebookUrl = "https://www.facebook.com/sharer/sharer.php?u="+ encodeURIComponent(this.linkURL);
    this.twitterUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(this.linkURL);
  }

  get linkURL():string{
    return this._linkURL;
  }

  facebookUrl:string;
  twitterUrl:string;

  constructor() { }

  ngOnInit()
  {

  }

}
