import { Component, OnInit, Input } from '@angular/core';
import { DataModel } from '../model/datamodel';

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @Input() public context:DataModel;

  constructor() { }

  ngOnInit() {
  }

}
