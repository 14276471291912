import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'takeawayimage',
  templateUrl: './takeawayimage.component.html',
  styleUrls: ['./takeawayimage.component.scss']
})
export class TakeawayimageComponent implements OnInit {

  @Input() imageSource: string;

  constructor() { }

  ngOnInit() {
  }

}
