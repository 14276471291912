import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalComponent } from './portal/portal.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { UpgradeBrowserComponent } from './upgrade-browser/upgrade-browser.component';

const routes: Routes = [
  { path: 'agent/:id', component: PortalComponent },
  { path: 'upgradebrowser', component:UpgradeBrowserComponent},
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
