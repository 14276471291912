import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataModel } from '../model/datamodel';
import { SkillLookup } from '../model/skillLookup';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { VideosModel } from '../model/videosModel';
import { isFormattedError } from '@angular/compiler';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as $ from 'jquery';


@Component({
  selector: 'portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit, AfterViewInit{


  public model:DataModel;
  private id;
  private headerClass:string;
  private days:number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
 
    public skillLookup:SkillLookup, 
    public videosModel:VideosModel,
    private deviceService: DeviceDetectorService
    ) 
    {
      this.checkBrowser();
      this.loadVideos();
  }

  ngAfterViewInit(): void {
    this.loadHeader();
    this.loadFooter();
  }

  loadHeader(){

    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.spymuseum.org/ajax/header');
    xhr.onload = function() {
        if (xhr.status === 200) {

          var response = xhr.responseText;
          var html = document.createElement('html');
          html.innerHTML = response;

          self.headerClass = $(html).find("body")[0].getAttribute("class");

          var head = $(html).find("head")[0].innerHTML;
          var header = $(html).find("header");
          
          //self.earmarkSVGs(header);
 
          var div = $("<div class='"+self.headerClass+"'></div>");
          div.append(header[0]);

          $("header").replaceWith(div);
          $("head").append(head);
        }
        else {
            alert('Request failed.  Returned status of ' + xhr.status);
        }
    };
    xhr.send();
  }

  earmarkSVGs(html){
      // parse html for svg elements
      $(html).find("use").each(function (index, value) {
        if(this.getBoundingClientRect().height == 0){

          var svgURL = this.getAttribute('xlink:href');

          var ajax = new XMLHttpRequest();
          ajax.open("GET", svgURL, true);
          ajax.send();
          ajax.onload = function(e) {
            var div = document.createElement("div");
            div.innerHTML = ajax.responseText;
            document.body.insertBefore(div, document.body.childNodes[0]);
          }
        }
      });
  }

  loadFooter(){

    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.spymuseum.org/ajax/footer');
    xhr.onload = function() {
        if (xhr.status === 200) {

          var response = xhr.responseText;
          var html = document.createElement('html');
          html.innerHTML = response;

          var footerBody = $(html).find("body")[0];

          var div = $("<div class='"+self.headerClass+"'></div>");
          div.append(footerBody.innerHTML);
          $("footer").replaceWith(div);
        }
        else {
            alert('Request failed.  Returned status of ' + xhr.status);
        }
    };
    xhr.send();
  }

  checkBrowser(){
    var version = parseInt(this.deviceService.browser_version.split(".")[0]);
    var supported = false;
    switch(this.deviceService.browser.toLowerCase()){
      case "ie":
          supported = version >= 11;
      break;

      case "ms-edge":
          supported = version >= 17;
      break;

      case "chrome":
      supported = version >= 71;
      break;
      
      case "safari":
      supported = version >= 12;
      break;

      case "samsung":
      supported = version >= 8.2;
      break;

      case "firefox":
      supported = version >= 65;
      break;
    };

    if(!supported){
      this.router.navigate(['/upgradebrowser'])
    }
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  updateDaysRemaining(){
    var expireDays = 30;
    var delta = Math.floor(( Date.now() - Date.parse(this.model.profile.missionDate)  ) / 86400000);
    this.days =  Math.max(0, Math.min(expireDays, expireDays - delta)); 
  }
  
  loadModel(){
    //eventually we'll pull the data endpoint from the url
    var xmlhttp = new XMLHttpRequest();
    var app = this;
    xmlhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        app.model = JSON.parse(this.responseText);
        app.updateDaysRemaining();
        app.createSkillLookup();
      }else{
        //console.log("Error loading JSON:" +this.response);
      }
    };
    
    var r = Math.round(Math.random() * 999999);

    var url ="https://undercovermission-debrief.spymuseum.org/" + this.id + ".json?r="+r;
    if(location.hostname === "localhost"){
      var url = "http://localhost:4200/" + this.id + ".json?r="+r;
    }
    
    xmlhttp.open("GET", url, true);
    xmlhttp.send();
  }

  loadVideos(){
    //eventually we'll pull the data endpoint from the url
    var xmlhttp = new XMLHttpRequest();
    var app = this;
    xmlhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        var model = JSON.parse(this.responseText);
        app.videosModel.linkText = model.linkText;
        app.videosModel.linkUrl = model.linkUrl;
        for(let video of model.videos){
          app.videosModel.videoLookup[video.id] = video.videos;
        }
    
        app.loadModel();
      }else{
        //console.log("Error loading JSON:" +this.response);
      }
    };
    xmlhttp.open("GET", "assets/portalvideos.json", true);
    xmlhttp.send();
  }

  createSkillLookup(){
    for(let score of this.model.skills){
      this.skillLookup.skills[score.id] = score.friendlyName;
    }
  }
}
