import { Component, OnInit, Input } from '@angular/core';
import { CareerLocation } from 'src/app/model/careerlocation';

@Component({
  selector: 'careerlocation',
  templateUrl: './careerlocation.component.html',
  styleUrls: ['./careerlocation.component.scss']
})
export class CareerlocationComponent implements OnInit {

  @Input() public context:CareerLocation;
  
  constructor() { }

  ngOnInit() {
  }

}
