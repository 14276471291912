import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SkillLookup {
   public skills = [];

   public medallions = [];

   public icons = [];

   constructor(){ 
      this.medallions["Strength"] = "Medallions1024_Strength.png"; 
      this.medallions["Nerves"] = "Medallions1024_Courage.png"; 
      this.medallions["Analytical"] = "Medallions1024_Analytical.png"; 
      this.medallions["Observational"] = "Medallions1024_Observation.png"; 
      this.medallions["Memory"] = "Medallions1024_memory.png"; 
      this.medallions["Stealth"] = "Medallions1024_Stealth.png"; 
      this.medallions["Strategic"] = "Medallions1024_Strategy.png"; 
      this.medallions["Technical"] = "Medallions1024_Technical.png"; 

      this.icons["Strength"] = "skillicon_strength.png"; 
      this.icons["Nerves"] = "skillicon_nerves-of-steel.png"; 
      this.icons["Analytical"] = "skillicon_analytical.png"; 
      this.icons["Observational"] = "skillicon_observation.png"; 
      this.icons["Memory"] = "skillicon_memory.png"; 
      this.icons["Stealth"] = "skillicon_stealth.png"; 
      this.icons["Strategic"] = "skillicon_strategy.png"; 
      this.icons["Technical"] = "skillicon_technical.png"; 
   }

}