import { ActivityModel } from './activitymodel';

export class SkillScoreModel{
    public skillname:string;
    public friendlyName:string;
    public skillID:string;
    public description:string;
    public iconUrl:string;
    public activities:ActivityModel[] = [];
}
