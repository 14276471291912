import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'activitytitle',
  templateUrl: './activitytitle.component.html',
  styleUrls: ['./activitytitle.component.scss']
})
export class ActivityTitleComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;

  constructor() { }

  ngOnInit() {
    
  }

}
