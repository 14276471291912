import { Component, OnInit, Input } from '@angular/core';
import { CareerLocation } from 'src/app/model/careerlocation';

@Component({
  selector: 'spycareer',
  templateUrl: './spycareer.component.html',
  styleUrls: ['./spycareer.component.scss']
})
export class SpycareerComponent implements OnInit {

  @Input() public context:CareerLocation[];

  constructor() { }

  ngOnInit() {
  }

}
