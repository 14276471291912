import { Pipe, PipeTransform } from '@angular/core';
import { SkillLookup } from '../model/skillLookup';

@Pipe({name: 'skillName'})
export class SkillNamePipe implements PipeTransform {

  constructor(public skillLookup:SkillLookup){
  }

  transform(value: string): string {
    var newValue = this.skillLookup.skills[value];
    return newValue ? newValue : value;
  }
}