import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TopScoreModel } from 'src/app/model/topscoremodel';

@Component({
  selector: 'topskill',
  templateUrl: './topskill.component.html',
  styleUrls: ['./topskill.component.scss']
})
export class TopskillComponent implements OnInit {
  
  constructor() { }
  
  _context :TopScoreModel;
  @Input() set context(value :TopScoreModel){
    this._context = value;
    this.socialURL = location.origin + "/awards/"+value.skillID.toLowerCase()+".html";
  }

  get context():TopScoreModel{
    return this._context;
  }

  socialURL:string;



  ngOnInit() {
  }

}
